import { useSelector } from "react-redux";
import { Navigate, RouteProps } from "react-router-dom";
import { AppState } from "../../store/configureStore";

interface PrivateRouteProps extends RouteProps {
    component: any;
}

export const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
    const token = useSelector((state: AppState) => state.user.token);
    const loggedIn = useSelector((state: AppState) => state.login.loggedIn);

    if (token && loggedIn) return <Component />;
    return <Navigate to="/login" />;
};
